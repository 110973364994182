.section {
  @apply container mx-auto py-4 px-4 pb-8 sm:px-12 md:px-4 lg:px-12;
}

.section-title {
  @apply mt-4 sm:mb-4 py-4 sm:py-6 text-center text-gray-700 border-b-2 border-t-2 border-gray-200;
}

.icons-list {
  @apply inline-flex items-center justify-center w-8 h-8 text-white border-2 border-white rounded-lg hover:bg-white hover:border-purple-600 hover:text-purple-600;
}

.icons-list-contacts {
  @apply inline-flex items-center justify-center w-8 h-8  text-[#d92cf9] border-2 border-[#d92cf9] rounded-lg hover:text-purple-600 hover:text-purple-600;
}
.footer {
  @apply text-center p-5 bg-gray-200 text-gray-600 text-xs  md:text-base;
}

.skill-card {
  @apply text-center p-2;
}
.skill-alt-text {
  @apply mt-2 text-sm capitalize text-gray-500 text-center;
}
.skills-section-title {
  @apply text-center  text-gray-500;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
